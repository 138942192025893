*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Wotfard, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-8 {
  grid-row: span 8 / span 8;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-8 {
  grid-row-start: 8;
}

.row-end-4 {
  grid-row-end: 4;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-14 {
  height: 3.5rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.-translate-x-12 {
  --tw-translate-x: -3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-16 {
  --tw-translate-x: -4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-24 {
  --tw-translate-y: -6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-32 {
  --tw-translate-y: -8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-10 {
  --tw-translate-x: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-14 {
  --tw-translate-x: 3.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-12 {
  --tw-rotate: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.snap-proximity {
  --tw-scroll-snap-strictness: proximity;
}

.snap-center {
  scroll-snap-align: center;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: .125rem;
}

.border-2 {
  border-width: 2px;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.bg-alternateBackground {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-white\/70 {
  background-color: #ffffffb3;
}

.fill-black {
  fill: #000;
}

.fill-current {
  fill: currentColor;
}

.stroke-neutralTextLight {
  stroke: #3d3b40;
}

.stroke-\[4px\] {
  stroke-width: 4px;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.font-serif {
  font-family: Poly, serif;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-neutralTextLight {
  --tw-text-opacity: 1;
  color: rgb(61 59 64 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(82 92 235 / var(--tw-text-opacity));
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90, .opacity-\[\.9\] {
  opacity: .9;
}

.opacity-\[1\] {
  opacity: 1;
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Wotfard;
  src: url("wotfard.08c4171a.woff2") format("woff2");
}

@font-face {
  font-family: Poly;
  src: url("Poly-Regular.2dd8c964.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  font-display: optional;
  margin: 0;
  padding: 0;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

:is(.dark .dark\:bg-black\/80) {
  background-color: #000c;
}

:is(.dark .dark\:bg-darkAlternateBackground) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 31 31 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-neutral-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

:is(.dark .dark\:fill-white) {
  fill: #fff;
}

:is(.dark .dark\:stroke-white) {
  stroke: #fff;
}

:is(.dark .dark\:text-gray-600) {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-neutralTextDark) {
  --tw-text-opacity: 1;
  color: rgb(238 238 238 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-primaryLight) {
  --tw-text-opacity: 1;
  color: rgb(209 197 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:row-start-8 {
    grid-row-start: 8;
  }

  .md\:mb-56 {
    margin-bottom: 14rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:mt-56 {
    margin-top: 14rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:w-\[100\%\], .md\:w-full {
    width: 100%;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-5 {
    gap: 1.25rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:text-start {
    text-align: start;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:justify-start {
    justify-content: flex-start;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
}

.buttonBack___1mlaL, .buttonFirst___2rhFr, .buttonLast___2yuh0, .buttonNext___2mOCa, .buttonNext___3Lm3s, .dot___3c3SI {
  cursor: pointer;
}

.image___xtQGH {
  width: 100%;
  height: 100%;
  display: block;
}

.spinner___27VUp {
  border: 4px solid #a9a9a9;
  border-top-color: #000;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  animation-name: spin___S3UuE;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}

@keyframes spin___S3UuE {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.container___2O72F {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.overlay___IV4qY {
  opacity: 0;
  cursor: zoom-in;
  transition: opacity .3s, transform .3s;
  position: absolute;
  inset: 0;
}

.hover___MYy31, .loading___1pvNI, .zoom___3kqYk {
  opacity: 1;
}

.imageLoadingSpinnerContainer___3UIPD {
  background-color: #f4f4f4;
  position: absolute;
  inset: 0;
}

.slide___3-Nqo {
  box-sizing: border-box;
  height: 0;
  margin: 0;
  list-style-type: none;
  display: block;
  position: relative;
}

.slide___3-Nqo:focus {
  outline: none !important;
}

.slideHorizontal___1NzNV {
  float: left;
}

[dir="rtl"] .slideHorizontal___1NzNV {
  direction: rtl;
  transform: scaleX(-1);
}

.slideInner___2mfX9 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.focusRing___1airF {
  pointer-events: none;
  outline-width: 5px;
  outline-style: solid;
  outline-color: Highlight;
  position: absolute;
  inset: 5px;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .focusRing___1airF {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.horizontalSlider___281Ls {
  touch-action: pan-y pinch-zoom;
  position: relative;
  overflow: hidden;
}

[dir="rtl"] .horizontalSlider___281Ls {
  direction: ltr;
  transform: scaleX(-1);
}

.horizontalSliderTray___1L-0W {
  width: 100%;
  overflow: hidden;
}

.verticalSlider___34ZFD {
  position: relative;
  overflow: hidden;
}

.verticalSliderTray___267D8 {
  overflow: hidden;
}

.verticalTray___12Key {
  float: left;
}

.verticalSlideTrayWrap___2nO7o {
  overflow: hidden;
}

.sliderTray___-vHFQ {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

.sliderAnimation___300FY {
  will-change: transform;
  transition: transform .5s cubic-bezier(.645, .045, .355, 1);
}

.masterSpinnerContainer___1Z6hB {
  background-color: #f4f4f4;
  position: absolute;
  inset: 0;
}

/*# sourceMappingURL=index.9b21d0b4.css.map */
