@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Wotfard";
  src: url("./assets/wotfard.woff2") format("woff2");
}

@font-face {
  font-family: "Poly";
  src: url("./assets/Poly-Regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  font-display: optional;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
